<template>
  <th-wrapper
    collapsable
    collapsed-by-default
    :subtitle="$t('pages.settings.integrations.mailjet.explainer')"
  >
    <!-- Title -->
    <template #title>
      <div class="flex items-center">
        <th-image
          contain
          height="30px"
          width="50px"
          :src="$t('pages.settings.integrations.mailjet.image')"
        />
        <span class="ml-2">
          {{ $t('pages.settings.integrations.mailjet.title') }}
        </span>
      </div>
    </template>

    <!-- Body -->
    <div>
      <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
        <el-row :gutter="20">
          <!-- API Key -->
          <el-col :xl="5" :lg="5" :md="12" :sm="12" class="mb-3">
            <el-form-item
              :label="
                $t('pages.settings.integrations.mailjet.edit.form.key.label')
              "
              prop="key"
              class="mb-0"
            >
              <el-tooltip
                :content="
                  $t(
                    'pages.settings.integrations.mailjet.edit.form.key.tooltip'
                  )
                "
              >
                <el-input
                  id="key"
                  v-model="form.key"
                  data-lpignore="true"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                />
              </el-tooltip>
            </el-form-item>
          </el-col>

          <!-- API Secret -->
          <el-col :xl="5" :lg="5" :md="12" :sm="12" class="mb-3">
            <el-form-item
              :label="
                $t('pages.settings.integrations.mailjet.edit.form.secret.label')
              "
              prop="secret"
              class="mb-0"
            >
              <el-tooltip
                :content="
                  $t(
                    'pages.settings.integrations.mailjet.edit.form.secret.tooltip'
                  )
                "
              >
                <el-input
                  id="secret"
                  v-model="form.secret"
                  data-lpignore="true"
                  autocomplete="new-password"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  :type="showApiKey ? undefined : 'password'"
                >
                  <template #append>
                    <el-button icon="Edit" @click="showApiKey = !showApiKey" />
                  </template>
                </el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>

          <!-- Template -->
          <el-col :xl="4" :lg="4" :md="8" :sm="12" class="mb-3">
            <el-form-item
              :label="
                $t(
                  'pages.settings.integrations.mailjet.edit.form.templates.generic.label'
                )
              "
              prop="templateGeneric"
              class="mb-0"
            >
              <el-tooltip
                :content="
                  $t(
                    'pages.settings.integrations.mailjet.edit.form.templates.generic.tooltip'
                  )
                "
              >
                <el-input-number
                  id="templateGeneric"
                  v-model="form.templateGeneric"
                  data-lpignore="true"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  :min="0"
                  :precision="0"
                  :controls="false"
                />
              </el-tooltip>
            </el-form-item>
          </el-col>

          <!-- Sender email -->
          <el-col :xl="5" :lg="5" :md="8" :sm="12" class="mb-3">
            <el-form-item
              :label="
                $t(
                  'pages.settings.integrations.mailjet.edit.form.sender.email.label'
                )
              "
              prop="senderEmail"
              class="mb-0"
            >
              <el-tooltip
                :content="
                  $t(
                    'pages.settings.integrations.mailjet.edit.form.sender.email.tooltip'
                  )
                "
              >
                <el-input
                  id="senderEmail"
                  v-model="form.senderEmail"
                  data-lpignore="true"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                />
              </el-tooltip>
            </el-form-item>
          </el-col>

          <!-- Sender name -->
          <el-col :xl="5" :lg="5" :md="8" :sm="12" class="mb-3">
            <el-form-item
              :label="
                $t(
                  'pages.settings.integrations.mailjet.edit.form.sender.name.label'
                )
              "
              prop="senderName"
              class="mb-0"
            >
              <el-tooltip
                :content="
                  $t(
                    'pages.settings.integrations.mailjet.edit.form.sender.name.tooltip'
                  )
                "
              >
                <el-input
                  id="senderName"
                  v-model="form.senderName"
                  data-lpignore="true"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                />
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Reset -->
        <el-button type="danger" plain @click="resetMailjet">
          {{ $t('pages.settings.integrations.mailjet.edit.form.reset') }}
        </el-button>
      </el-form>
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  name: 'SettingsIntegrationsMailjet',
  data() {
    return {
      form: {},
      showApiKey: false,
      loading: false
    }
  },
  computed: {
    rules() {
      return {
        key: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        secret: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        senderEmail: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: this.$t(
              'pages.customers.edit.form.field_warnings.type.email'
            )
          },
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        senderName: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        templateGeneric: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }
    },
    isEmptyMailjetConfiguration() {
      return (
        !this.senderName &&
        !this.senderEmail &&
        !this.templateGeneric &&
        !this.key &&
        !this.secret
      )
    },
    key: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'integrations.mailjet.key',
          null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'integrations.mailjet.key',
          value: newValue || null
        })
      }
    },
    secret: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'integrations.mailjet.secret',
          null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'integrations.mailjet.secret',
          value: newValue || null
        })
      }
    },
    senderName: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'integrations.mailjet.sender.name',
          null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'integrations.mailjet.sender.name',
          value: newValue || null
        })
      }
    },
    senderEmail: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'integrations.mailjet.sender.email',
          null
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'integrations.mailjet.sender.email',
          value: newValue || null
        })
      }
    },
    templateGeneric: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'integrations.mailjet.templates.generic',
          0
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'integrations.mailjet.templates.generic',
          value: Number(newValue) || null
        })
      }
    }
  },
  watch: {
    secret: function (newInput) {
      if (newInput !== this.form.secret) this.form.secret = newInput
    },
    'form.secret': function (newInput) {
      if (newInput !== this.secret) this.secret = newInput
    },
    key: function (newInput) {
      if (newInput !== this.form.key) this.form.key = newInput
    },
    'form.key': function (newInput) {
      if (newInput !== this.key) this.key = newInput
    },
    templateGeneric: function (newInput) {
      if (newInput !== this.form.templateGeneric)
        this.form.templateGeneric = newInput
    },
    'form.templateGeneric': function (newInput) {
      if (newInput !== this.templateGeneric) this.templateGeneric = newInput
    },
    senderEmail: function (newInput) {
      if (newInput !== this.form.senderEmail) this.form.senderEmail = newInput
    },
    'form.senderEmail': function (newInput) {
      if (newInput !== this.senderEmail) this.senderEmail = newInput
    },
    senderName: function (newInput) {
      if (newInput !== this.form.senderName) this.form.senderName = newInput
    },
    'form.senderName': function (newInput) {
      if (newInput !== this.senderName) this.senderName = newInput
    }
  },
  mounted() {
    // Wait for the configurations to load
    this.form = this.setForm()
  },
  methods: {
    resetMailjet() {
      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: 'integrations.mailjet',
        value: null
      })
    },
    validate(cb) {
      if (this.isEmptyMailjetConfiguration) {
        // If empty, then set the object to null, to be able to persist, and dont validate
        this.resetMailjet()
        cb(true)
      } else {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return this.$message({
              type: 'warning',
              message: this.$t(
                'common.forms.rules.field_warnings.invalid_inputs.required'
              )
            })
          }
          return cb(valid)
        })
      }
    },
    setForm() {
      return {
        senderEmail: this.senderEmail,
        senderName: this.senderName,
        key: this.key,
        secret: this.secret,
        templateGeneric: this.templateGeneric
      }
    }
  }
}
</script>
