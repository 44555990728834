<template>
  <section>
    <mailjet ref="form" />
  </section>
</template>

<script>
import Mailjet from './components/mailjet'

export default {
  components: {
    Mailjet
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.integrations.title')
    }
  },
  data() {
    return {
      activeNames: []
    }
  },
  methods: {
    handleChange() {},
    validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    }
  }
}
</script>
